<template>
  <div>
    <calendarCheck v-if="!check" :data="data" :callback="setCheck" />
    <transition name="flixFadeIn">
      <div :key="notLoggedIn">
        <div v-if="check && variables.user === false && notLoggedIn">
          <div class="flix-alert flix-alert-info">{{ $t('message.assistentSaveInfo') }}</div>
          <calendarLogin class="flix-form-group" :saveCalendar="function() { saveCalendar() }"/>
        </div>
        <div v-if="check && !notLoggedIn">
          <calendarSave :services="services" :data="data" class="flix-form-group" :setNotLogin="function(callback) { setNotLogin(callback) }"/>
          <div class="flix-form-group">
            <editBtn :onClick = "function() { open = !open }" :settings="{block: true}">
              <template v-slot:text>
                {{ $t('message.preview') }}: {{ $tc('message.calendar', 1) }}
              </template>
            </editBtn>
          </div>
          <div class="flix-form-group flix-text-info" v-if="open">
            <flixIcon :id='"info-sign"' /> <span class="flix-html-strong"> {{ $t('message.preview') }}</span> = {{ $t('message.bookingsNotAllowed') }}
          </div>
          <calendarPreview v-if="open" :settings="{noTitle: true}" :key="$store.getters.serviceKey" :services="$store.getters.services" :data="data" class="flix-form-group"/>

        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data () {
    return {
      open: false,
      check: false,
      notLoggedIn: false,
      variables: {},
      saveCalendar: function () {}
    }
  },
  components: {
    calendarCheck () { return import('@/components/assistent/components/assets/check') },
    calendarPreview () { return import('@/components/assistent/components/assets/preview') },
    calendarLogin () { return import('@/components/assistent/components/assets/login') },
    calendarSave () { return import('@/components/assistent/components/assets/save') }
  },
  props: { data: Object, callback: Function, services: Array },
  methods: {
    setSave () {
      this.callback(this.data)
    },
    setCheck () {
      this.check = true
    },
    setNotLogin (callback) {
      this.saveCalendar = callback
      this.notLoggedIn = true
    }
  },
  mounted () {
    this.variables = this.$getUserVariables()
  }
}
</script>
