<script>
import preview from '@/components/assistent/components/preview'
export default {
  extends: preview,
  components: {
    calendarSave () { return import('@/components/assistentPrivate/components/save') }
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
